import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { ReferralCardSkeleton } from "@src/appV2/Accounts/WorkplaceReferrals/components";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { type PlacementWithDetails } from "../api/useFetchPaginatedPlacements";
import { useReferrals } from "../api/useReferrals";
import { useHiringReferralFlag } from "../lib/useHiringReferralFlag";
import { GetHiredAndBonusBottomSheet } from "./GetHiredAndBonusBottomSheet";
import { IncentiveBanner } from "./IncentiveBanner";
import { RedeemReferralCode } from "./RedeemReferralCode";
import { ReferAFriendBottomSheet } from "./ReferAFriendBottomSheet";
import { getReferralState, type ReferralStateValue } from "./referralState";

interface PlacementSignOnBonusProps {
  placementDetails?: PlacementWithDetails;
  getHiredStates: ReferralStateValue[];
  referAFriendStates: ReferralStateValue[];
}

export function PlacementSignOnBonus(props: PlacementSignOnBonusProps) {
  const { placementDetails, getHiredStates, referAFriendStates } = props;
  const worker = useDefinedWorker();
  const hiringReferralFlag = useHiringReferralFlag();
  const getHiredAndBonusModal = useModalState();
  const referAFriendModal = useModalState();

  const { data: referrals, isLoading: isSignOnBonusLoading } = useReferrals(
    {
      filter: {
        refereeId: worker.userId,
        refereeType: "WORKER",
        referralProgram: {
          name: "PLACEMENT_SIGN_ON_BONUS",
        },
      },
    },
    { enabled: hiringReferralFlag.signOnBonus.enabled }
  );

  if (isSignOnBonusLoading && hiringReferralFlag.signOnBonus.enabled) {
    return <ReferralCardSkeleton count={1} height={{ body: 254 }} />;
  }

  if (!referrals || !hiringReferralFlag.signOnBonus.enabled) {
    return null;
  }

  const referralState = getReferralState(worker, referrals, hiringReferralFlag.redeemedCutoffDate);
  const placementReferral = referrals.find(
    ({ referralProgram }) =>
      referralProgram && placementDetails && referralProgram.name === "PLACEMENT_SIGN_ON_BONUS"
  );

  return (
    <>
      {getHiredStates.includes(referralState) ? (
        <IncentiveBanner
          title={`Get hired, get ${formatCentsAsUsd(
            hiringReferralFlag.signOnBonus.bonusAmountInUnits,
            {
              maximumFractionDigits: 0,
            }
          )}!`}
          description={`Get hired, work 5 shifts, and we'll reward you with a ${formatCentsAsUsd(
            hiringReferralFlag.signOnBonus.bonusAmountInUnits,
            {
              maximumFractionDigits: 0,
            }
          )} bonus`}
          placementDetails={placementDetails}
          ctaText="Learn more"
          referralState={referralState}
          onClick={() => {
            getHiredAndBonusModal.openModal();
          }}
        />
      ) : undefined}
      {referAFriendStates.includes(referralState) ? (
        <>
          <IncentiveBanner
            title={`Refer a friend, earn ${formatCentsAsUsd(
              hiringReferralFlag.signOnBonus.bonusAmountInUnits,
              {
                maximumFractionDigits: 0,
              }
            )}!`}
            description={`Invite a friend to apply—if they get hired and complete 5 shifts, you both earn a ${formatCentsAsUsd(
              hiringReferralFlag.signOnBonus.bonusAmountInUnits,
              {
                maximumFractionDigits: 0,
              }
            )} bonus`}
            placementDetails={placementDetails}
            ctaText="Refer now"
            referralState={referralState}
            onClick={() => {
              referAFriendModal.openModal();
            }}
          />
          {placementDetails &&
            (isDefined(placementReferral) ? undefined : (
              <RedeemReferralCode placementId={placementDetails.id} />
            ))}
        </>
      ) : undefined}
      <GetHiredAndBonusBottomSheet
        placementDetails={placementDetails}
        modalState={getHiredAndBonusModal}
        referral={placementReferral}
        referralState={referralState}
      />
      <ReferAFriendBottomSheet
        modalState={referAFriendModal}
        placementDetails={placementDetails}
        referralCode={worker.referralCode}
        referralState={referralState}
      />
    </>
  );
}
